import { useContractRead } from "wagmi";
import { ABI_NFT } from "./contract";
import { useEffect, useState } from "react";

export default function useWalletAtIndex(index: number | undefined) {
  const [svg, setSvg] = useState<string>();
  const [wallet, setWallet] = useState<string>();

  const { data } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "walletAtIndex",
    watch: false,
    args: [index],
    enabled: index !== undefined,
  });

  useEffect(() => {
    if (data) {
      const [walletData, svgData] = data as [string, string];
      setSvg(svgData);
      setWallet(walletData);
    }
  }, [data]);

  useEffect(() => {
    if (index === undefined) {
      setSvg(undefined);
      setWallet(undefined);
    }
  }, [index]);

  return {
    svg: svg,
    wallet: wallet,
  };
}
