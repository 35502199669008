import { Box } from "@chakra-ui/react";
import useLocalSVG from "./useLocalSVG";
import "./Whole.css";

export default function Whole() {
  const svg = useLocalSVG("whole.svg");

  return (
    <Box
      className="fade-line"
      maxW="100%"
      w="100%"
      h="100%"
      top="0%"
      position="absolute"
    >
      <div
        dangerouslySetInnerHTML={{ __html: svg }}
        style={{
          padding: "0px",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      />
    </Box>
  );
}
