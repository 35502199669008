import { useContractRead } from "wagmi";
import { ABI_NFT } from "./contract";
import { useEffect, useState } from "react";

export default function useOwnerOf(tokenId: number | undefined) {
  const [owner, setOwner] = useState<string>();
  const { data } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "ownerOf",
    enabled: tokenId !== undefined,
    args: [tokenId],
  });

  useEffect(() => {
    const addressData = data as string;
    setOwner(addressData);
  }, [data]);

  return owner;
}
