import {
  Button,
  Flex,
  HStack,
  Input,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useContractRead } from "wagmi";
import { ABI_NFT } from "../contract";

function Search() {
  const [currentIndex, setCurrentIndex] = useState<number>();
  const [isInputValid, setIsInputValid] = useState(true);
  const [value, setValue] = useState("");
  const handleChange = (event: any) => setValue(event.target.value);
  const [svg, setSvg] = useState<string>();
  const [wallet, setWallet] = useState<string>();
  const toast = useToast();

  const { data, isError } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "walletAtIndex",
    watch: false,
    args: [currentIndex],
    enabled: currentIndex !== undefined,
  });

  useEffect(() => {
    if (isError) {
      toast({
        title: "Error",
        description:
          "There was an error, check the index is within boundaries.",
        isClosable: true,
        status: "error",
        containerStyle: {
          backgroundColor: "black",
          borderColor: "red",
        },
      });
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      const [walletData, svgData] = data as [string, string];
      setSvg(svgData);
      setWallet(walletData);
    }
  }, [data]);

  useEffect(() => {
    if (value) {
      if (parseInt(value) >= 0) {
        setIsInputValid(true);
      } else {
        setIsInputValid(false);
      }
    }
  }, [value]);

  return (
    <Flex
      width="100%"
      direction="column"
      height="100%"
      align="center"
      justify="center"
    >
      <Spacer />
      <Text>Find who's where</Text>
      <HStack marginTop={4} marginBottom={4}>
        <Input
          value={value}
          onChange={handleChange}
          placeholder="A position, starts from 0"
          isInvalid={isInputValid}
        />
        <Button
          onClick={() =>
            parseInt(value) >= 0 && setCurrentIndex(parseInt(value))
          }
        >
          Search
        </Button>
      </HStack>
      <Text fontSize="15px">{wallet}</Text>
      {svg !== undefined && (
        <div
          dangerouslySetInnerHTML={{ __html: svg }}
          style={{
            borderColor: "hsl(25,0%,10%)",
            borderWidth: "1px",
            padding: "0px",
            width: "150px",
            height: "150px",
          }}
        />
      )}
      <Spacer />
    </Flex>
  );
}

export default Search;
