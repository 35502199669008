import { useContractRead } from "wagmi";
import { ABI_NFT } from "./contract";
import { useEffect, useState } from "react";

export default function useTokenUri(
  tokenId: number | undefined,
  doRefetch: boolean
) {
  const [svg, setSvg] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const [height, setHeiight] = useState<number>();
  const [relativePosition, setRelativePosition] = useState<number>();
  const [absolutePosition, setAbsolutePosition] = useState<number>();

  const { data, refetch } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "tokenURI",
    watch: false,
    args: [tokenId],
    enabled: tokenId !== undefined,
  });

  useEffect(() => {
    if (doRefetch) {
      refetch();
    }
  }, [doRefetch]);

  useEffect(() => {
    if (data) {
      const jsonData = data as string;
      const json = JSON.parse(jsonData.slice(22));
      setSvg(json["image"].slice(19));
      setSubject(
        json["attributes"].filter((a: any) => a["trait_type"] === "Subject")[0][
          "value"
        ]
      );
      setRelativePosition(
        json["attributes"].filter(
          (a: any) => a["trait_type"] === "Position, Relative"
        )[0]["value"]
      );
      setAbsolutePosition(
        json["attributes"].filter(
          (a: any) => a["trait_type"] === "Position, Absolute"
        )[0]["value"]
      );
      setHeiight(
        json["attributes"].filter((a: any) => a["trait_type"] === "Height")[0][
          "value"
        ]
      );
    }
  }, [data]);

  useEffect(() => {
    if (tokenId === undefined) {
      setSvg(undefined);
      setSubject(undefined);
      setRelativePosition(undefined);
      setAbsolutePosition(undefined);
      setHeiight(undefined);
    }
  }, [tokenId]);

  return [svg, subject, height, relativePosition, absolutePosition];
}
