import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Link,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import "./ExploreDesktop.css";
import EnterButton from "../EnterButton";
import InFrontText from "../InFrontText";
import MintButton from "../MintButton";
import CountdownText from "../Countdown";
import ConnectButton from "../ConnectButton";
import { EtherscanIcon, OpenseaIcon } from "../Icons";
import Search from "./Search";
import useFlags from "../useFlags";
import Update from "./Update";

function ExploreDesktop(props: {
  size: number;
  auctionRef: React.RefObject<HTMLHeadingElement>;
  line: string;
  setUpdate: (doUpdate: boolean) => void;
  update: boolean;
  firstPosition: bigint | undefined;
  positions: bigint[];
  mintBegin: bigint;
  queueBegin: bigint;
  canMint: boolean;
  currentBlock: bigint | undefined;
  walletSvg: string | undefined;
  ensOrAddress: string | undefined;
  count: number;
  lineLength: bigint | undefined;
  currentTokenId: bigint | undefined;
}) {
  // Determine if the viewport width allows for side content
  const [boxSize, setBoxSize] = useState<number>(100);

  const { mintBegun, mintInProgress } = useFlags(
    props.mintBegin,
    props.currentBlock,
    props.lineLength
  );
  const executeScroll = () =>
    //aboutRef.current?.scrollBy({
    window.scrollBy({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    });

  const executeScrollToRules = () => {
    props.auctionRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    const size = props.size / 4.5;
    if (size < 180) {
      setBoxSize(180);
    } else {
      setBoxSize(size);
    }
  }, [props.size]);

  return (
    <Flex width="100%" height="100vh" align="center" justify="center">
      <Box
        flex="1"
        height="100vh"
        textAlign="center"
        paddingTop={5}
        paddingBottom={5}
      >
        <Flex direction="column" height="100vh">
          <Box className="box" width={boxSize} height={boxSize}>
            <Link href="/">
              <Text variant="homeHeading">The Queue</Text>
            </Link>
            <InFrontText
              firstPosition={props.firstPosition}
              canMint={props.canMint}
              mintBegin={props.mintBegin}
              currentBlock={props.currentBlock}
              count={props.count}
              update={props.update}
              lineLength={props.lineLength}
            />
            {!mintBegun && (
              <EnterButton
                mintBegin={props.mintBegin}
                currentBlock={props.currentBlock}
                update={() => props.setUpdate(true)}
                count={props.count}
                queueBegin={props.queueBegin}
              />
            )}
            {/*
              <LineLengthText
                lineLength={lineLength === undefined ? BigInt(0) : lineLength}
                mintBegin={mintBegin}
                currentBlock={currentBlock}
              ></LineLengthText>
  */}
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            {(!mintBegun || mintInProgress) && (
              <Box>
                <Text variant="homeHeading">Mint</Text>
                <MintButton
                  mintBegin={props.mintBegin}
                  currentBlock={props.currentBlock}
                  positions={props.positions}
                  canMint={props.canMint}
                  lineLength={props.lineLength}
                  count={props.count}
                />

                <CountdownText
                  mintBegin={props.mintBegin}
                  currentBlock={props.currentBlock}
                />
                {props.mintBegin !== undefined &&
                  props.currentBlock !== undefined &&
                  props.lineLength !== undefined &&
                  props.currentBlock >= props.mintBegin &&
                  props.mintBegin + props.lineLength >= props.currentBlock && (
                    <Text className="blink" variant="home">
                      MINTING
                    </Text>
                  )}
              </Box>
            )}
            {mintBegun && !mintInProgress && (
              <VStack>
                <Text>Sale ended.</Text>
                <Text>View on Opensea</Text>
                <Link
                  isExternal
                  href="https://opensea.io/collection/the-queue-by-miragenesi"
                >
                  <IconButton
                    style={{
                      paddingTop: "4px",
                    }}
                    aria-label="Opensea"
                    icon={<OpenseaIcon />}
                  ></IconButton>
                </Link>
              </VStack>
            )}
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            <Text variant="homeHeading">You</Text>
            <ConnectButton
              walletSvg={props.walletSvg}
              ensOrAddress={props.ensOrAddress}
            />
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            {props.mintBegin === undefined ||
              props.currentBlock === undefined ||
              (props.mintBegin > props.currentBlock && (
                <Box>
                  <Text variant="homeHeading">Length</Text>
                  <Text variant="home">{props.lineLength?.toString()}</Text>
                </Box>
              ))}
            {props.mintBegin !== undefined &&
              props.currentBlock !== undefined &&
              props.mintBegin <= props.currentBlock && (
                <Box>
                  <Text variant="homeHeading">Supply</Text>
                  <Text variant="home">{props.lineLength?.toString()}</Text>
                </Box>
              )}
          </Box>

          <Spacer />
        </Flex>
      </Box>

      {/* Central box with fixed props.size */}
      <Box position="relative" width={props.size} height={props.size}>
        <Update />
      </Box>

      {/* Right box taking up remaining space */}
      <Box
        flex="1"
        height="100vh"
        textAlign="center"
        paddingTop={5}
        paddingBottom={5}
      >
        <Flex direction="column" height="100vh">
          <Box className="box" width={boxSize} height={boxSize}>
            <Text variant="homeHeading">NFT</Text>
            <Text variant="home">
              A fragment of the line.
              <br />
              In space, and in time.
              <br />
              100% On-chain.
            </Text>
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            <Text variant="homeHeading">Rules</Text>
            <Text variant="home">
              Queue up.
              <br /> Mint when it's your turn.
            </Text>
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            <Link>
              <Text variant="homeHeading">Explore</Text>
            </Link>
            <Link href="/#explore">
              <Button>EXPLORE</Button>
            </Link>
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            <Text variant="homeHeading">About</Text>
            <HStack>
              <Link
                isExternal
                href="https://etherscan.io/address/0x999c11bbFc1e6C60EC132E1d2D5319c9D67E9999"
              >
                <IconButton
                  aria-label="Etherscan"
                  icon={<EtherscanIcon />}
                ></IconButton>
              </Link>
              <Link
                isExternal
                href="https://opensea.io/collection/the-queue-by-miragenesi"
              >
                <IconButton
                  style={{
                    paddingTop: "4px",
                  }}
                  aria-label="Opensea"
                  icon={<OpenseaIcon />}
                ></IconButton>
              </Link>
            </HStack>
            <Link
              marginTop={1}
              onClick={executeScroll}
              fontWeight="bold"
              fontSize="xl"
            >
              ↓
            </Link>
          </Box>
          <Spacer />
        </Flex>
      </Box>
    </Flex>
  );
}

export default ExploreDesktop;
