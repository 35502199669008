import {
  Box,
  Flex,
  HStack,
  IconButton,
  Link,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import CountdownText from "../Countdown";
import "./ExploreMobile.css";
import ConnectButtonMobile from "../ConnectButtonMobile";
import InFrontText from "../InFrontText";
import MintButtonMobile from "../MintButtonMobile";
import EnterButtonMobile from "../EnterButtonMobile";
import Search from "./Search";
import { OpenseaIcon } from "../Icons";
import useFlags from "../useFlags";

function ExploreMobile(props: {
  size: number;
  auctionRef: React.RefObject<HTMLHeadingElement>;
  line: string;
  setUpdate: (doUpdate: boolean) => void;
  update: boolean;
  firstPosition: bigint | undefined;
  positions: bigint[];
  mintBegin: bigint;
  queueBegin: bigint;
  canMint: boolean;
  currentBlock: bigint | undefined;
  walletSvg: string | undefined;
  ensOrAddress: string | undefined;
  count: number;
  lineLength: bigint | undefined;
  currentTokenId: bigint | undefined;
}) {
  const { mintBegun, mintInProgress } = useFlags(
    props.mintBegin,
    props.currentBlock,
    props.lineLength
  );

  return (
    <Flex width="100%" marginTop={5} align="center" direction="column">
      {/* Left box taking up remaining space */}
      <Flex direction="row" width="100%">
        {(!mintBegun || mintInProgress) && <Spacer />}
        {mintInProgress && (
          <Box
            className="box box-mobile"
            width={props.size / 4.2}
            height={props.size / 4.2}
          >
            <Text className="blink" variant="home">
              MINTING
            </Text>
          </Box>
        )}
        {!mintBegun && (
          <Box
            className="box box-mobile"
            width={props.size / 4.2}
            height={props.size / 4.2}
          >
            <EnterButtonMobile
              mintBegin={props.mintBegin}
              currentBlock={props.currentBlock}
              update={() => props.setUpdate(true)}
              count={props.count}
              queueBegin={props.queueBegin}
            />

            <CountdownText
              mintBegin={props.queueBegin}
              currentBlock={props.currentBlock}
            />
          </Box>
        )}
        <Spacer />
        <Box
          className="box box-mobile"
          width={props.size / 4.2}
          height={props.size / 4.2}
        >
          <Link href="/">
            <Text fontSize="1.4em">The Queue</Text>
          </Link>
        </Box>
        <Spacer />
        <Box
          className="box box-mobile"
          width={props.size / 4.2}
          height={props.size / 4.2}
        >
          <ConnectButtonMobile
            walletSvg={props.walletSvg}
            ensOrAddress={props.ensOrAddress}
          />
        </Box>
        <Spacer />
      </Flex>

      {/* Central box with fixed props.size */}
      <Box position="relative" width={props.size} height={props.size}>
        <Search />
      </Box>
      <HStack>
        <Box
          className="box box-mobile"
          width={props.size / 4.2}
          height={props.size / 4.2}
          marginTop={5}
          marginBottom={10}
        >
          {props.mintBegin === undefined ||
            props.currentBlock === undefined ||
            (props.mintBegin > props.currentBlock && (
              <Box>
                <Text variant="home">
                  Length: {props.lineLength?.toString()}
                </Text>
                <InFrontText
                  lineLength={props.lineLength}
                  firstPosition={props.firstPosition}
                  canMint={props.canMint}
                  mintBegin={props.mintBegin}
                  currentBlock={props.currentBlock}
                  count={props.count}
                  update={props.update}
                />
              </Box>
            ))}
          {mintBegun && (
            <Box>
              <Text variant="home">
                Supply
                <br />
                {props.lineLength?.toString()}
              </Text>
            </Box>
          )}
        </Box>
        {props.currentBlock !== undefined && props.mintBegin !== undefined && (
          <Box
            className="box box-mobile"
            marginTop={5}
            marginBottom={10}
            width={props.size / 4.2}
            height={props.size / 4.2}
          >
            Mint
            <MintButtonMobile
              mintBegin={props.mintBegin}
              currentBlock={props.currentBlock}
              positions={props.positions}
              canMint={props.canMint}
              lineLength={props.lineLength}
              count={props.count}
            />
          </Box>
        )}
        {props.currentBlock !== undefined && props.mintBegin !== undefined && (
          <Box
            width={props.size / 4.2}
            height={props.size / 4.2}
            className="box box-mobile"
            marginTop={5}
            marginBottom={10}
          >
            {!mintBegun && (
              <Box>
                <Text fontSize="15px">minting in</Text>
                <CountdownText
                  mintBegin={props.mintBegin}
                  currentBlock={props.currentBlock}
                />
              </Box>
            )}
            {mintInProgress && (
              <InFrontText
                lineLength={props.lineLength}
                firstPosition={props.firstPosition}
                canMint={props.canMint}
                mintBegin={props.mintBegin}
                currentBlock={props.currentBlock}
                count={props.count}
                update={props.update}
              />
            )}
            {mintBegun && !mintInProgress && (
              <VStack>
                <Text>Opensea</Text>
                <Link
                  isExternal
                  href="https://opensea.io/collection/the-queue-by-miragenesi"
                >
                  <IconButton
                    style={{
                      paddingTop: "4px",
                    }}
                    aria-label="Opensea"
                    icon={<OpenseaIcon />}
                  ></IconButton>
                </Link>
              </VStack>
            )}
          </Box>
        )}
      </HStack>
    </Flex>
  );
}

export default ExploreMobile;
