import {
  Box,
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Tooltip,
  extendTheme,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useAccount, useContractWrite, useWaitForTransaction } from "wagmi";
import { ABI_NFT } from "./contract";
import { parseEther } from "viem";
import { useEffect, useState } from "react";
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import useDiscount from "./useDiscount";
import ConfettiExplosion from "react-confetti-explosion";
import PriceText from "./PriceText";
import PriceTextMobile from "./PriceTextMobile";
import usePrice from "./usePrice";
import useFlags from "./useFlags";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  dialog: {
    borderRadius: "3",
    bg: `black.100`,
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});

type MintButtonProps = {
  positions: bigint[] | undefined;
  canMint: boolean;
  mintBegin: bigint | undefined;
  lineLength: bigint | undefined;
  currentBlock: bigint | undefined;
  count: number;
};

export const theme = extendTheme({
  components: { Modal: modalTheme },
});

export default function MintButtonMobile(props: MintButtonProps) {
  // 4. Use modal hook
  const { address, isConnected } = useAccount();
  const { hasDiscount, freeAmount, proof } = useDiscount(address);
  const [amount, setAmount] = useState(2);
  const price = usePrice(props.mintBegin, props.currentBlock, props.lineLength);
  const [minted, setMinted] = useState(false);
  const [amountLeft, setAmountLeft] = useState(props.count * 2);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAmount(parseInt(event.target.value));
  };

  const { data, write, isError } = useContractWrite({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: withDiscount() ? "mintDiscount" : "mint",
    value: actualPrice(),
    args: withDiscount()
      ? [amount, props.positions, freeAmount, proof]
      : [amount, props.positions],
  });

  useEffect(() => {
    if (isError) {
      toast({
        title: "Error",
        description:
          "There was an error while minting. Check your allowance and wallet balance",
        isClosable: true,
        status: "error",
        containerStyle: {
          backgroundColor: "black",
          borderColor: "red",
        },
      });
    }
  }, [isError]);

  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
    confirmations: 1,
    onSuccess: () => {
      setMinted(true);
      setAmountLeft(amountLeft - amount);
      localStorage.setItem(address!, "true");
    },
    onError: (error) => {
      toast({
        title: "Error",
        description:
          "There was an error while minting. Check your allowance and wallet balance",
        isClosable: true,
        status: "error",
        containerStyle: {
          backgroundColor: "black",
          borderColor: "red",
        },
      });
    },
  });

  const { mintBegun, mintInProgress } = useFlags(
    props.mintBegin,
    props.currentBlock,
    props.lineLength
  );

  useEffect(() => {
    if (props.count > 0) {
      setAmountLeft(Number(props.count * 2));
    }
  }, [props.count]);

  useEffect(() => {
    if (waitForTransaction.isError) {
      const timer = setTimeout(() => {
        //refetch();
        //setTries(tries + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [waitForTransaction.isError, props.positions]);

  const Overlay = () => <ModalOverlay bg="blackAlpha.800" />;

  function withDiscount() {
    return (
      hasDiscount &&
      address !== undefined &&
      localStorage.getItem(address) !== "true"
    );
  }

  function mintingDisabled() {
    return (
      waitForTransaction.isLoading ||
      waitForTransaction.isError ||
      !isConnected ||
      !props.canMint
    );
  }

  function discountPrice() {
    return (price * BigInt(85)) / BigInt(100);
  }

  function actualPrice() {
    if (withDiscount()) {
      return BigInt(amount - freeAmount) * discountPrice();
    }

    return BigInt(amount) * price;
  }

  return (
    <Box marginTop={1} marginBottom={1}>
      {(!mintBegun || mintInProgress) && (
        <Box fontSize="10px">
          <Tooltip label="Get in Line first" isDisabled={!mintingDisabled()}>
            <Button
              height="40px"
              width="90px"
              isDisabled={mintingDisabled()}
              isLoading={waitForTransaction.isLoading}
              onClick={() => onOpen()}
            >
              <PriceTextMobile
                discountPrice={discountPrice()}
                price={price}
                hasDiscount={withDiscount()}
              />
            </Button>
          </Tooltip>
          <br />
          {withDiscount() && freeAmount !== undefined && freeAmount > 0 && (
            <Text fontWeight="bold" marginTop={1} color="red">
              1 FREE
            </Text>
          )}
        </Box>
      )}
      {mintBegun && !mintInProgress && (
        <Button height="50px" isDisabled={true}>
          ENDED
        </Button>
      )}
      {
        <Modal isOpen={isOpen} onClose={onClose} motionPreset="none" isCentered>
          <Overlay />
          {minted === false ? (
            <ModalContent>
              <ModalHeader>How many?</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Select
                  onChange={handleChange}
                  value={amount}
                  placeholder="Select option"
                >
                  {Array.from(Array(amountLeft).keys()).map((i) => {
                    return (
                      <option key={i} value={i + 1}>
                        {i + 1}
                      </option>
                    );
                  })}
                </Select>
              </ModalBody>

              <ModalFooter>
                <Button
                  height="50px"
                  isDisabled={
                    waitForTransaction.isLoading ||
                    waitForTransaction.isError ||
                    !isConnected ||
                    !props.canMint
                  }
                  isLoading={waitForTransaction.isLoading}
                  onClick={() => write?.()}
                >
                  Mint{" "}
                  <PriceText
                    discountPrice={actualPrice()}
                    price={price * BigInt(amount - freeAmount)}
                    hasDiscount={withDiscount()}
                  />
                </Button>
                {withDiscount() &&
                  freeAmount !== undefined &&
                  freeAmount > 0 && (
                    <Text fontWeight="bold" marginLeft={1} color="red">
                      1 FREE
                    </Text>
                  )}
              </ModalFooter>
            </ModalContent>
          ) : (
            <ModalContent>
              <ModalHeader>Congratulations!</ModalHeader>
              <ModalCloseButton onClick={() => setMinted(false)} />
              <ModalBody>
                You made it! Explore your collection on{" "}
                <Link href="https://opensea.io/collection/the-queue-by-miragenesi">
                  Opensea
                </Link>
                <Box width="10%" margin="0 auto">
                  <ConfettiExplosion
                    zIndex={99999}
                    width={1000}
                    colors={["#ffffff", "#f700ff", "#00ffe5"]}
                  />
                </Box>
              </ModalBody>
            </ModalContent>
          )}
        </Modal>
      }
    </Box>
  );
}
