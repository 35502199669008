import { useContractRead } from "wagmi";
import { ABI_NFT } from "./contract";
import { useEffect, useState } from "react";
import { parseEther } from "viem";

export default function usePrice(
  mintBegin: bigint | undefined,
  currentBlock: bigint | undefined,
  lineLength: bigint | undefined
) {
  const tolerance = BigInt(process.env.REACT_APP_BLOCK_TOLERANCE || 5);
  const increment = parseEther(process.env.REACT_APP_INCREMENT!);
  const startFee = parseEther(process.env.REACT_APP_START_PRICE!);
  const [price, setPrice] = useState(startFee);

  const currentSlot =
    currentBlock && mintBegin && currentBlock > mintBegin
      ? currentBlock - mintBegin
      : BigInt(0);

  useEffect(() => {
    if (mintBegin && currentBlock && lineLength) {
      if (currentSlot === BigInt(0)) {
        setPrice(startFee + increment * tolerance);
      } else {
        setPrice(slotPrice(currentSlot, lineLength));
      }
    } else {
      setPrice(startFee + increment * tolerance);
    }
  }, [
    increment,
    startFee,
    currentSlot,
    currentBlock,
    lineLength,
    mintBegin,
    tolerance,
  ]);

  function slotPrice(slot: bigint, lineLength: bigint) {
    if (Number(slot) < lineLength / BigInt(2)) {
      const maxPrice =
        startFee +
        increment *
          (lineLength / BigInt(2) -
            BigInt(Number(lineLength) % 2 ^ 1) * BigInt(1));
      const tolerancePrice = startFee + increment * (slot + tolerance);
      if (tolerancePrice > maxPrice) {
        return maxPrice;
      }

      return tolerancePrice;
    } else {
      if (slot >= lineLength) {
        return startFee;
      }

      return (
        startFee + increment * BigInt(lineLength - slot - BigInt(1) + tolerance)
      );
    }
  }

  return price;
}
