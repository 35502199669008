import { useContractRead } from "wagmi";
import { ABI_NFT } from "./contract";
import { useEffect, useState } from "react";

export default function useCurrentTokenId() {
  let [currentTokenId, setCurrentTokenId] = useState<bigint>();

  const { data, refetch } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "currentToken",
    watch: false, // FIXME: decrease frequency
  });

  useEffect(() => {
    // Set the interval and keep the interval ID to clear it in the future
    const intervalId = setInterval(refetch, 5000); // where n is the interval in seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // The empty dependency array ensures this effect runs only once upon mounting

  useEffect(() => {
    const currentTokenIdData = data as bigint;
    if (currentTokenIdData !== currentTokenId) {
      setCurrentTokenId(currentTokenIdData);
    }
  }, [data]);

  return currentTokenId;
}
