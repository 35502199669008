export default function useFlags(
  mintBegin: bigint | undefined,
  currentBlock: bigint | undefined,
  lineLength: bigint | undefined
) {
  function mintInProgress() {
    return (
      currentBlock !== undefined &&
      lineLength !== undefined &&
      mintBegin !== undefined &&
      currentBlock >= mintBegin &&
      mintBegin + lineLength > currentBlock
    );
  }

  function mintBegun() {
    return (
      currentBlock !== undefined &&
      lineLength !== undefined &&
      mintBegin !== undefined &&
      currentBlock >= mintBegin
    );
  }

  function end() {
    return (
      currentBlock !== undefined &&
      lineLength !== undefined &&
      mintBegin !== undefined &&
      currentBlock >= mintBegin + lineLength + BigInt(2)
    );
  }

  return {
    mintBegun: mintBegun(),
    mintInProgress: mintInProgress(),
    end: end(),
  };
}
