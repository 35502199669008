import {
  Button,
  Flex,
  HStack,
  Input,
  Spacer,
  Text,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { ABI_NFT } from "../contract";
import useWalletAtIndex from "../useWalletAtIndex";
import useTokenUri from "../useTokenUri";
import useOwnerOf from "../useOwnerOf";

function Update() {
  const { address } = useAccount();
  const [isOwner, setIsOwner] = useState(false);
  const [subjectValue, setSubjectValue] = useState("");
  const [tokenIdValue, setTokenIdValue] = useState("");
  const [heightValue, setHeightValue] = useState("");
  const [subject, setSubject] = useState<number>();
  const [tokenId, setTokenId] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [doRefetch, setDoRefetch] = useState(false);
  const owner = useOwnerOf(tokenId);
  const [
    currentSvg,
    currentSubject,
    currentHeight,
    currentRelativePosition,
    currentAbsolutePosition,
  ] = useTokenUri(tokenId, doRefetch);
  const { svg, wallet } = useWalletAtIndex(
    tokenId && subject ? subject : undefined
  );

  const handleChangeSubject = (event: any, newTokenId = tokenId) => {
    setSubjectValue(event.target.value);
    if (
      isInputValid(event.target.value) &&
      newTokenId &&
      parseInt(event.target.value) >= newTokenId - 1
    )
      setSubject(parseInt(event.target.value));
    else setSubject(undefined);
  };

  const handleChangeTokenID = (event: any) => {
    setTokenIdValue(event.target.value);
    if (
      isInputValid(event.target.value) &&
      parseInt(event.target.value) > 0 &&
      parseInt(event.target.value) < 152
    ) {
      setTokenId(parseInt(event.target.value));
      handleChangeSubject(
        { target: { value: subjectValue } },
        parseInt(event.target.value)
      );
    } else {
      setTokenId(undefined);
      handleChangeSubject({ target: { value: subjectValue } }, undefined);
    }
  };

  const handleChangeHeight = (event: any) => {
    setHeightValue(event.target.value);
    if (
      isInputValid(event.target.value) &&
      parseInt(event.target.value) > 0 &&
      parseInt(event.target.value) < 32
    )
      setHeight(parseInt(event.target.value));
    else setHeight(undefined);
  };
  const toast = useToast();

  const { data, isError, write } = useContractWrite({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "explore",
    args: [tokenId, height, subject! - (tokenId! - 1)],
  });

  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
    confirmations: 1,
    onSuccess: () => {
      setDoRefetch(true);
      toast({
        title: "Success",
        description: "NFT Updated",
        isClosable: true,
        status: "success",
        containerStyle: {
          backgroundColor: "black",
          borderColor: "green",
        },
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "There was an error.",
        isClosable: true,
        status: "error",
        containerStyle: {
          backgroundColor: "black",
          borderColor: "red",
        },
      });
    },
  });

  function update() {
    setDoRefetch(false);
    write();
  }

  useEffect(() => {
    if (owner !== undefined) setIsOwner(owner === address);
    else setIsOwner(false);
  }, [owner]);

  useEffect(() => {
    if (isError) {
      toast({
        title: "Error",
        description: "There was an error.",
        isClosable: true,
        status: "error",
        containerStyle: {
          backgroundColor: "black",
          borderColor: "red",
        },
      });
    }
  }, [isError]);

  function isInputValid(input: string | undefined) {
    return input !== undefined && parseInt(input) >= 0;
  }

  return (
    <Flex
      width="100%"
      direction="column"
      height="100%"
      align="center"
      justify="center"
    >
      <Spacer />
      <Text>Explore Your Q</Text>
      <Text textAlign="center" fontSize="13px">
        Focus your NFT on a new subject and/or at a new height.
        <br />
        The new Subject should be one existing in your Q (not lower the the
        First in Line).
        <br />
        To find a good height, it's try and error.
      </Text>
      <HStack marginTop={4} marginBottom={4}>
        <VStack>
          <Text fontSize="15px" width="100%" textAlign="left">
            Token ID (just number)
          </Text>
          <Input
            value={tokenIdValue}
            onChange={handleChangeTokenID}
            placeholder="Token ID (just number)"
            isInvalid={tokenId === undefined}
          />
        </VStack>
        <VStack>
          <Text fontSize="15px" width="100%" textAlign="left">
            New Subject Index
          </Text>
          <Input
            value={subjectValue}
            onChange={handleChangeSubject}
            disabled={tokenId === undefined}
            placeholder="New subject"
            isInvalid={subject === undefined}
          />
        </VStack>
        <VStack>
          <Text fontSize="15px" width="100%" textAlign="left">
            Height (1 - 31)
          </Text>
          <Input
            value={heightValue}
            onChange={handleChangeHeight}
            placeholder="Height (1 - 31)"
            isInvalid={height === undefined}
          />
        </VStack>
      </HStack>
      <Text>Current NFT Data</Text>
      <HStack>
        <div
          dangerouslySetInnerHTML={{
            __html: currentSvg !== undefined ? currentSvg : "",
          }}
          style={{
            borderColor: "grey",
            borderWidth: "1px",
            padding: "10px",
            width: "190px",
            height: "190px",
          }}
        />
        <VStack
          borderWidth={1}
          borderColor="grey"
          height="100%"
          textAlign="left"
          padding="5px"
        >
          <Spacer />
          <Text width="100%">
            Subject: {currentSubject !== undefined ? currentSubject : "???"}
          </Text>
          <Spacer />
          <Text width="100%">
            Subject Index:{" "}
            {currentAbsolutePosition !== undefined
              ? currentAbsolutePosition
              : "???"}
          </Text>
          <Spacer />
          <Text width="100%">
            Height: {currentHeight !== undefined ? currentHeight : "???"}
          </Text>
          <Spacer />
          <Text width="100%">
            First in Line Index: {tokenId !== undefined ? tokenId - 1 : "???"}
          </Text>
          <Spacer />
        </VStack>
      </HStack>
      <HStack>
        <VStack>
          <Text marginTop={5}>New Subject</Text>
          <Tooltip label={wallet} closeDelay={1000}>
            <Text fontSize="15px">
              {wallet !== undefined
                ? wallet.lastIndexOf("eth") === -1
                  ? `${wallet.slice(0, 5)}..${wallet.slice(wallet.length - 3)}`
                  : wallet
                : "none selected"}
            </Text>
          </Tooltip>

          <div
            dangerouslySetInnerHTML={{ __html: svg !== undefined ? svg : "" }}
            style={{
              borderColor: "hsl(25,0%,10%)",
              borderWidth: "1px",
              padding: "0px",
              width: "170px",
              height: "170px",
            }}
          />
        </VStack>
        <VStack>
          <Text marginTop={5}>New NFT Look</Text>
          <Text fontSize="15px">How will it look?</Text>
          <Text
            borderColor="hsl(25,0%,10%)"
            borderWidth="1px"
            width="170px"
            height="170px"
            padding="10px"
            fontSize="15px"
          >
            You will discover only after executing the update.
          </Text>
        </VStack>
      </HStack>
      <Button
        marginTop={2}
        isLoading={waitForTransaction.isLoading}
        width="150px"
        isDisabled={
          subject === undefined ||
          tokenId === undefined ||
          height === undefined ||
          !isOwner
        }
        onClick={() => update()}
      >
        Update
      </Button>
      {subject === undefined && (
        <Text color="red" fontSize="10px">
          *subject invalid
        </Text>
      )}
      {tokenId === undefined && (
        <Text color="red" fontSize="10px">
          *token id invalid
        </Text>
      )}
      {height === undefined && (
        <Text color="red" fontSize="10px">
          *height invalid
        </Text>
      )}
      {!isOwner && tokenId !== undefined && (
        <Text color="red" fontSize="10px">
          *you are not the owner of the NFT
        </Text>
      )}
      <Spacer />
    </Flex>
  );
}

export default Update;
