import { Chain, WagmiConfig, createConfig } from "wagmi";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { mainnet, sepolia, localhost } from "wagmi/chains";
import { HashRouter, Route, Routes } from "react-router-dom";

import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
} from "@chakra-ui/react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { modalAnatomy } from "@chakra-ui/anatomy";
import Main from "./Main";
import Explore from "./ExploreView/Explore";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const baseStyle = definePartsStyle({
  dialog: {
    borderColor: "hsl(25,0%,90%)",
    borderWidth: "1px",
    borderRadius: 3,
    color: "hsl(25,0%,90%)",
    textAlign: "center",
    bg: `black`,
  },
  footer: {
    textAlign: "center",
    justifyContent: "center",
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});

const breakpoints = {
  base: "0px",
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

const TextStyle = defineStyleConfig({
  // Two variants: outline and solid
  variants: {
    homeHeading: {
      fontSize: { base: "1.8em", "2xl": "2em" },
    },
    aboutHeading: {
      fontSize: { base: "1.7em", "2xl": "1.8em" },
    },
    home: {
      fontSize: { base: "0.9em", "2xl": "1em" },
    },
    about: {
      fontSize: { base: "1em", "2xl": "1.1em" },
    },
  },
  // The default size and variant values
  defaultProps: {
    variant: "about",
  },
});

const theme = extendTheme({
  breakpoints,
  fonts: {
    heading: `"OxaniumHeading", "Arial", "serif"`,
    body: `"Oxanium", "Arial", "serif"`,
  },
  styles: {
    global: {
      // styles for the `body`
      "html, body": {
        bg: "black",
        color: "hsl(25,0%,90%)",
      },
    },
  },

  components: {
    Text: TextStyle,
    Heading: {
      variants: {
        home: {
          size: { base: "md", "2xl": "lg" },
          as: { base: "h3", "2xl": "h2" },
        },
        about: {
          size: { base: "lg", "2xl": "xl" },
          as: { base: "h2", "2xl": "h1" },
        },
      },
      defaultProps: {
        variant: "about",
      },
    },
    Modal: modalTheme,
    Button: {
      baseStyle: {
        bgColor: "black",
        borderColor: "hsl(25,0%,90%)",
        borderWidth: "1px",
        borderRadius: 3,
        color: "hsl(25,0%,90%)",
        filter: "drop-shadow(0 0 0.3rem hsl(25,0%,90%,0.5))",
        _hover: {
          //bgColor: "var(--chakra-colors-whiteAlpha-500)",
          borderBottomColor: "black",
          borderLeftColor: "black",
          borderTopColor: "black",
          borderRightColor: "black",
          filter: "drop-shadow(0 0 1rem hsl(25,0%,90%,0.5))",
        },
      },
      variants: {
        // Make a variant, we'll call it `base` here and leave it empty
        base: {},
        secondary: {
          //...define other variants
        },
      },
      defaultProps: {
        // Then here we set the base variant as the default
        variant: "base",
      },
    },
  },
});

const localhostChain: Chain = {
  id: 666666,
  name: "Localhost",
  network: "localhost",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH",
  },
  rpcUrls: localhost.rpcUrls,
  testnet: true,
};

let config = createConfig(
  getDefaultConfig({
    // Required API Keys
    alchemyId: process.env.REACT_APP_ALCHEMY_API_KEY, // or infuraId
    walletConnectProjectId:
      process.env.REACT_APP_WALLET_CONNECT_ID ||
      "6bfc3872088ae3c3edcb2c73606355af",

    // Required
    appName: "The Queue",

    // Optional
    appDescription: "The Queue - Queue, Wait, Mint",
    appUrl: "https://family.co", // your app's url
    appIcon: "https://family.co/logo.png", // your app's icon, no bigger than 1024x1024px (max. 1MB),
    chains: [mainnet],
  })
);

function App() {
  return (
    <WagmiConfig config={config}>
      <ConnectKitProvider
        theme="midnight"
        customTheme={{
          "--ck-overlay-background": "rgba(0, 0, 0, 0.5)",
          "--ck-border-radius": "3",
          "--ck-primary-button-border-radius": "3",
          "--ck-body-background": "black",
          "--ck-font-family": `"Avalon", "Arial", "serif"`,
          "--ck-body-color": "hsl(25,0%,90%)",
        }}
      >
        <ChakraProvider theme={theme}>
          <HashRouter>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/explore" element={<Explore />} />
            </Routes>
          </HashRouter>
        </ChakraProvider>
      </ConnectKitProvider>
    </WagmiConfig>
  );
}

export default App;
