import { VStack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import About from "../About";
import "./Explore.css";
import ExploreDesktop from "./ExploreDesktop";
import ExploreMobile from "./ExploreMobile";
import useLine from "../useLine";
import useBlock from "../useBlock";
import useAccountData from "../useAccountData";
import useLineLength from "../useLineLength";

function Explore() {
  // Determine if the viewport width allows for side content
  const [size, setSize] = useState(window.innerHeight);
  const [mobile, setMobile] = useState(false);
  const mintBegin = BigInt(process.env.REACT_APP_MINT_BEGIN!);
  const queueBegin = BigInt(process.env.REACT_APP_QUEUE_BEGIN!);
  const [update, setUpdate] = useState(false);
  const line = useLine(mobile, update);
  const currentBlock = useBlock();

  const lineLength = useLineLength();
  const currentTokenId = BigInt(0); //useCurrentTokenId();

  const { ensOrAddress, walletSvg, firstPosition, positions, count, canMint } =
    useAccountData({
      update: update,
      unsetUpdate: () => {
        setUpdate(false);
      },
      mintBegin,
      currentBlock,
    });

  const aboutRef = useRef<HTMLHeadingElement>(null);
  const rulesRef = useRef<HTMLHeadingElement>(null);

  function updateSize() {
    let newSize = Math.min(window.innerWidth, window.innerHeight);
    if (window.innerWidth < 1024 && window.innerWidth < window.innerHeight) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    // Calculate the smaller of viewport width or height to maintain aspect ratio

    setSize(newSize);
  }

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <VStack maxWidth="100%" width="100%">
      {mobile === false && (
        <ExploreDesktop
          auctionRef={rulesRef}
          size={size}
          line={line}
          setUpdate={setUpdate}
          update={update}
          firstPosition={firstPosition}
          positions={positions}
          mintBegin={mintBegin}
          canMint={canMint}
          currentBlock={currentBlock}
          walletSvg={walletSvg}
          ensOrAddress={ensOrAddress}
          count={count}
          queueBegin={queueBegin}
          lineLength={lineLength}
          currentTokenId={currentTokenId}
        />
      )}
      {mobile === true && (
        <ExploreMobile
          auctionRef={rulesRef}
          size={size}
          line={line}
          setUpdate={setUpdate}
          update={update}
          firstPosition={firstPosition}
          positions={positions}
          mintBegin={mintBegin}
          canMint={canMint}
          currentBlock={currentBlock}
          walletSvg={walletSvg}
          ensOrAddress={ensOrAddress}
          count={count}
          queueBegin={queueBegin}
          lineLength={lineLength}
          currentTokenId={currentTokenId}
        />
      )}
      <About mobile={mobile} aboutRef={aboutRef} rulesRef={rulesRef} />
    </VStack>
  );
}

export default Explore;
